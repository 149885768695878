import './Contact.css'

function Contact() {

  return (
    <div className='contact-container'>
      <div className='contact-info-container'>
        <div className='contact-info-left'>
          {/* Office Appointment Hours are:
          M, T: 8:30-4:30 PM
          W: 8:30-4 PM
          Th: 8:30-3
          F: 730-10:45 AM */}
          <h3 className='contact-info-header'>Office Appointment Hours</h3>
          <p className='contact-info-text'>
            <strong>Monday:</strong> 8:30 AM - 4:30 PM <br />
            <strong>Tuesday:</strong> 8:30 AM - 4:30 PM <br />
            <strong>Wednesday:</strong> 8:30 AM - 4:00 PM <br />
            <strong>Thursday:</strong> 8:30 AM - 3:00 PM <br />
            <strong>Friday:</strong> 7:30 AM - 10:45 AM <br />

          </p>
          
          <h3 className='contact-info-header'>Allergy Shot Hours*</h3>
          <p className='contact-info-text'>
            <strong>Monday:</strong> 8:30 AM - 7:00 PM <br />
            <strong>Tuesday:</strong> 8:30 AM - 7:00 PM <br />
            <strong>Wednesday:</strong> 8:30 AM - 6:00 PM <br />
            <strong>Thursday:</strong> 8:30 AM - 4:00 PM <br />
            <strong>Friday:</strong> 7:30 AM - 12:00 PM <br />

          </p>
          <p className='footer-text' id='lunch'>
            Closed for Lunch from 12:30-1:00 PM
          </p>
          <p className='footer-text' id='shots'>
            *Allergy shots are given by appointment only <br />
            Last allergy shot appointment is 40 minutes before closing <br />
          </p>
        </div>
        <div className='contact-info-right'>
          <h3 className='contact-info-header'>Contact</h3>
          <p className='contact-info-text'>
            <i className="fa-solid fa-phone fa-xs footer-icons"></i>Phone: <a href="tel:(410)730-6000">(410) 730-6000 </a><br />
            <i className="fa-solid fa-fax fa-xs footer-icons"></i>Fax: (443) 979-7944 <br />
          </p>
          <h3 className='contact-info-header'>Office Address</h3>
          <p className='contact-info-text'>
            9891 Broken Land Pkwy, Suite 100 <br />
            Columbia, MD 21046
          </p>
          <div className='contact-map'>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12370.193727091148!2d-76.86232215617488!3d39.18498534851089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7de48ff08c21b%3A0x5071ae473ae17072!2s9891%20Broken%20Land%20Pkwy%20STE%20100%2C%20Columbia%2C%20MD%2021046!5e0!3m2!1sen!2sus!4v1693522906554!5m2!1sen!2sus" style={{ width: "75%", height: "100%", border: "0", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}
            title='Google Map to Allergy and Asthma of Central Maryland'></iframe>
          </div>

        </div>
      </div>
      {/* Holiday Hours */}
      <div className='contact-holiday-hours'>
        <h3>Holiday Hours</h3>
        <p className='contact-info-text'>
          <strong>Thursday, November 28, 2024:  </strong> Closed <br />
          <strong>Friday, November 29, 2024:  </strong> Closed <br />
          <strong>Tuesday, December 24, 2024:  </strong> 8:30 AM - 1:00 PM <br />
          <strong>Wednesday, December 25, 2024:  </strong> Closed <br />
          <strong>Tuesday, December 31, 2024:  </strong> 8:30 AM - 5:00 PM <br />
          <strong>Wednesday, January 1, 2025:  </strong> Closed <br />

        </p>
      </div>
      <div className='contact-detail-container'>

      <div className='contact-direction-container'>
        <div className='contact-location-container'>
          <h3>For General Appointments</h3>
          <p>(New & Follow-Up Visits, Food Challenges, OIT, Skin Testing)</p>
          <p>Enter directly through <strong>Lobby Door </strong> indicated in <span style={{ color: 'Blue' }}>Blue</span></p>
        </div>
        <div className='contact-location-container'>
          <h3>For <strong>Allergy Shot</strong> and <strong>Biologic Shot</strong> Appointments</h3>
          <p>(Xolair, Nucala, Fasenra, Dupixent)</p>
          <p>Enter directly through <strong>Exterior Door </strong> indicated in <span style={{ color: 'Red' }}>Red</span></p>
        </div>
      </div>
      <div className='contact-image-container'>
        <img src="/static/images/location.jpeg" alt='location' />
      </div>
      </div>
    </div>

  )
}
export default Contact